<template>
  <div>
    <v-breadcrumbs
      class="pt-3 pl-7"
      :items="[{ text: 'Website Profiles', exact: true,  to: { name: 'website-profiles' } }, { text: oldWebsiteProfile.name }]"
    >
    </v-breadcrumbs>
    <v-row
      class="mt-0"
    >

      <v-col
        cols="12"
        md="4"
      >

        <v-card
          flat
          class="mx-3 pb-9"
        >
          <v-toolbar
            dark
            class="tertiary"
            dense
            elevation="0"
          >
            <v-toolbar-title>Website Profile</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="editWebsiteProfileClick()"
              :disabled="!user.can('website-profile.update')"
            >
              <v-icon color="secondary" small>fas fa-edit</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>

            <dl class="row table mt-5">

              <dt class="col-5">Name:</dt>
              <dd class="col-7">{{ oldWebsiteProfile.name }}</dd>

            </dl>

          </v-card-text>

          <website-profile-dialog
            v-model="websiteProfileDialog"
            :attributes="websiteProfile"
            :errors="websiteProfileErrors"
            @save="saveWebsiteProfileClick()"
          ></website-profile-dialog>


        </v-card>


      </v-col>


      <v-col
        cols="12"
        md="8"
      >
        <v-card
          flat
          class="mx-3 pb-9"
        >
          <v-toolbar
            dark
            class="tertiary"
            dense
            elevation="0"
          >
            <v-toolbar-title>Categories</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              @click="resetWebsiteProfileCategoryClick()"
              :disabled="!user.can('website-profile-category.update')"
            >
              <v-icon color="secondary" small>fas fa-times</v-icon>
              <span class="ml-2">Reset</span>
            </v-btn>
            <v-btn
              @click="saveWebsiteProfileCategoryClick()"
              :disabled="!user.can('website-profile-category.update')"
            >
              <v-icon color="secondary" small>fas fa-save</v-icon>
              <span class="ml-2">Save</span>
            </v-btn>

          </v-toolbar>

          <v-data-table
            :class="{ 'mt-5': true, 'clickable': user.can('website-profile.update') }"
            disable-filtering
            disable-pagination
            disable-sort
            hide-default-footer
            :headers="categoryHeaders"
            :items="categories"
          >

            <template v-slot:item.sales_margin="{ item }">
              <v-text-field
                dense
                outlined
                class="my-1"
                v-model="salesMargins[item.id]"
                :error="!!websiteProfileErrors[`category_items.${item.id}`]?.length"
                hide-details
              ></v-text-field>

            </template>

          </v-data-table>

        </v-card>
      </v-col>
    </v-row>


  </div>
</template>

<script>
/* eslint-disable */
import { inject, ref, watch, nextTick } from 'vue'
import WebsiteProfileDialog from '@/components/WebsiteProfileDialog.vue'
import { useRouter, useRoute } from 'vue-router/composables'

export default {
  components: {
    WebsiteProfileDialog,
  },
  setup() {
    const api = inject('api')
    const loader = inject('loader')
    const { user, utils } = api
    const router = useRouter()
    const route = useRoute()

    const categoryCollection = api.collection('categories')
    const { items: categories } = categoryCollection.all()

    const websiteProfileModel = api.model('website-profiles/:id', {
      params: {
        expand: 'category_items',
      }
    })
    const { attributes: websiteProfile, errors: websiteProfileErrors, oldAttributes: oldWebsiteProfile, isBusy } = websiteProfileModel
    loader.bindRef(isBusy)

    const websiteProfileDialog = ref(false)
    const salesMargins = ref({})

    function resetSalesMargins() {
      if (websiteProfile.value.category_items) {
        const items = {}
        for(const categoryItem of websiteProfile.value.category_items) {
          items[categoryItem.category_id] = categoryItem.sales_margin
        }
        salesMargins.value = items
      }
    }

    function scrollToError() {
      nextTick(() => {
        const el = document.querySelector(".error--text:first-of-type")
        if (el) el.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      })
    }


    // afterFind: () => resetSalesMargins(),
    //   afterSave: () => resetSalesMargins(),
    //   afterValidationError: () => scrollToError(),


    watch(() => route, ({ params }) => {
      if (params.id) {
        websiteProfileModel.find(params.id).then(() => {
          resetSalesMargins()
        }).catch(() => {
          router.push({
            name: 'website-profiles',
          })
        })
      }
    }, { immediate: true })

    function editWebsiteProfileClick() {
      websiteProfileModel.reset(['name'])
      websiteProfileDialog.value = true
    }

    function saveWebsiteProfileClick() {
      websiteProfileModel.save(['name']).then(() => {
        websiteProfileDialog.value = false
      })
    }

    function resetWebsiteProfileCategoryClick() {
      websiteProfileModel.reset(['category_items'])
      resetSalesMargins()
    }

    function saveWebsiteProfileCategoryClick() {
      const categoryItems = {}

      for(const [categoryId, salesMargin] of Object.entries(salesMargins.value)) {
        if (!utils.isEmpty(salesMargin)) {
          categoryItems[categoryId] = {
            category_id: categoryId,
            sales_margin: salesMargin,
          }
        }
      }

      websiteProfileModel.setAttribute('category_items', categoryItems)
      websiteProfileModel.save(['category_items']).then(() => {
        resetSalesMargins()
      }).catch(error => {
        if (error.response.status === 422) {
          scrollToError()
        }
      })
    }


    const categoryHeaders = [
      { text: 'Name', value: 'name' },
      { text: 'Sales Margin', value: 'sales_margin' },
    ]


    return {
      user,
      websiteProfile,
      websiteProfileErrors,
      oldWebsiteProfile,
      websiteProfileDialog,
      categories,
      salesMargins,
      categoryHeaders,
      editWebsiteProfileClick,
      saveWebsiteProfileClick,
      resetWebsiteProfileCategoryClick,
      saveWebsiteProfileCategoryClick,
    }

  },
}
</script>

<style scoped>

:deep(.v-data-table.clickable table tbody tr:not(.v-data-table__empty-wrapper):hover) {
  cursor: pointer;
}

dl.table dt, dl.table dd {
  padding-top: 2px;
  padding-bottom: 2px;
}


</style>